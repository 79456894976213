@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap');

*{
  font-family: 'Impact', sans-serif;
}
body{
  background-color: #000000;
}
.App {
  text-align: center;
  background-color: #000000;
  width:100%;
  display: flex;
  flex-direction: column;
  
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 8vw;
  font-weight: 700;
  color: #f0f0f0; 
}




