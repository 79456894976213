.info-container{
    width:100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
}

.info-title{
    display: flex;
    flex-direction: row;
    
    font-size:3.5vw; 
    padding-bottom: 20px;
}
.white{
    color:aliceblue;
}.blue{
    color:#00ffde;
    padding: 0px 5px;
    font-weight: 700;
}
.yellow{
    color: #fecd27;
}

.content{
    display: flex;
    flex-direction: column;
    color:aliceblue;
    font-size: min(2.5vw, 30px);
    row-gap: 30px;
    max-width: 650px
}
.row{
    font-size: 2vw;
    display: flex;
    justify-content: center;
    flex-direction: row;
}