.mint-container{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 130px;
    margin-bottom: 150px;
    margin-top: 30px;
}
.title{
    color:aliceblue;
    font-size: 20px;
    font-weight: 500;
}
.minted{
    color:aliceblue;
    font-size: 20px;
    font-weight: 500;
    padding-top:20px
}

.counter{
    color: #fecd27;
    font-size: max(2.5vw, 25px);
    font-weight: 500;
}

.counter-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}
.amount{
    font-size: 5vw;
    padding: 0px 20px;
}

.counter-btn{
    font-size: 3vw;
    cursor: pointer;
}
.message{
    color: aliceblue;
    font-size: 1vw;
}
.success{

    font-size: 2vw;
}

.btn{
    padding: 20px 0px 50px 0px;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
    background-color: transparent;
    color: #fecd27 !important;
    border: 1px solid;
    font-weight: 500 !important;
}
.p-button-label {
    transition: all .2s;
    font-weight: 500 !important;
}