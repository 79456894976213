.bar{
    background-color: #fecd27;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

p{
    margin-bottom: 0px;
    margin-top: 0px;
}
.regular{
    color:rgb(12, 12, 12);
    font-size: 2.5vw;
    font-weight: 500;
    line-height: 5px;
}
.cntr{
    color:rgb(12, 12, 12);
    font-size: 4vw;
    font-weight: 1000;
    line-height: 10px;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

@media only screen and (max-width : 768px) {
    .bar{
        height: 20vh;
    }
}