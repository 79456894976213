.carousel-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* width:100vw; */
    padding-bottom:100px;
}
.tt{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.carousel-img{
    width: 100%;
    height: auto;
    max-width: 300px;
    min-width: 200px;
}

@media only screen and (max-width : 768px) {
    

}