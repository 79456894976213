.roadmap-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.what-we-might-do{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    font-weight: 500;
    height: 300px;
}
.what-we-will-do{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
    font-weight: 500;
    height: 300px;
}
.gray{
    color: rgb(53, 53, 53);
}
.white{
    color: aliceblue;
}

.merch{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 400px;
    background-color: #222222;
    width: 100%;
    
}
.merch-img{
    width: 100%;
    height: auto;
    max-width: 680px;
    padding-top: 90px;
}
.comic{
    background-color: #22222200;
}

.mobile {
    background-image: url("./mobile_bg.png");
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: cover;
    background-position-x: right;
}
.mobile-img{
    width: 100%;
    height: auto;
    max-width: 400px;
    padding-top: 0px;
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.right{
    align-items: flex-end;
}
.left-padded{
    margin-right: 100px;
}
.t{
    font-size: 3vw;
    font-weight: 500;
}
.tx{
    font-size: 3vw;
    font-weight: 500;
}
.p{
    font-size: 2.2vw;
    font-weight: 500;
}

.comic-img{
    padding-top: 50px;
    margin-right:100px;
    width: 100%;
    height: auto;
    max-width: 500px;
}
.clown-img{
    padding-top: 100px;
    margin-right:100px;
    width: 100%;
    height: auto;
    max-width: 600px;
}
.mint-info{
    background-color: #00ffde;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* padding: 0px 300px; */
}
.small{
    font-size: 2vw;
    
}
.big{
    font-size: 4.5vw;
}
.center{
    justify-content: center;
    align-items: center;
}
.sold-out{
    max-width:850px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
}
.t1{
    font-size: 2.5vw;
    font-weight: 500;
    padding-bottom: 50px;
}
.p1{
    font-size: 1.8vw;
    font-weight: 500;
}
.red{
    color: red;
}
.dark{
    background-color: #101010;
}

@media only screen and (max-width : 768px) {
    .what-we-might-do{
        height: 20vh;
        font-size: 4vh;
    }
    .merch-img{
        width: 100%;
        height: auto;
        max-width: 380px;
        padding-top: 0px;
    }
    .comic{
        padding: 0px 20px;
    }
    .comic-img{
        padding-top: 0px;
        margin-right:0px;
        width: 100%;
        height: auto;
        max-width: 480px;
        
    }
    .what-we-will-do{
        font-size: 4vw;
    }
    .mint-info{
        height: 20vh;
    }
    .sold-out{
        padding:100px 50px;
        font-size: 5vw;
        row-gap: 5px;
    }
    .t1{
        font-size: 4.5vw;
        font-weight: 500;
        padding-bottom: 50px;
    }
    .p1{
        font-size: 3.8vw;
        font-weight: 500;
    }
    .merch{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: auto;
        background-color: #222222;
    }
    .dark{
        background-color: #101010;
    }
    .clown-img{
        /* max-width: 330px; */
        
    }
    .column{
        align-items: unset;
    }
    
    .ready{
        padding-left: 20px;
    }
    .tx{
        font-size: 5vw;
        font-weight: 1000;
    }
}